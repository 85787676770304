import { graphql, PageProps } from 'gatsby';
import { keyBy } from 'lodash';
import React from 'react';
import { useTheme } from 'styled-components';
import MDX from '../cms/MDX';
import ColouredPageSection from '../components/ColouredPageSection';
import CommitteeMember from '../components/CommitteeMember';
import FeaturedProgrammesContainer from '../components/FeaturedProgrammesContainer';
import Section from '../components/formatting/Section';
import SectionDescription from '../components/formatting/SectionDescription';
import WithHeroLayout from '../layouts/WithHeroLayout';

interface Props extends PageProps {
  data: {
    file: {
      childMdx: {
        frontmatter: {
          title: string;
          content: string;
          non_members: {
            name: string;
            role: string;
          }[];
          members: {
            email: string;
            role: string;
          }[];
          start_year: string;
          end_year: string;
        };
      };
    };

    users: {
      edges: {
        node: {
          childMdx: {
            frontmatter: {
              bio: string;
              name: string;
              profile_image?: string;
            };
          };
        };
      }[];
    };
  };
}

const CommitteeTemplate: React.FC<Props> = function (props) {
  const { data } = props;

  const { frontmatter } = data.file.childMdx;
  const {
    title,
    content,
    non_members,
    members,
    start_year,
    end_year,
  } = frontmatter;

  const allUsers = keyBy(
    data.users.edges.map(edge => edge.node),
    'childMdx.frontmatter.email'
  );

  const theme = useTheme();

  return (
    <WithHeroLayout pageTitle={title}>
      <Section>
        <MDX>{content}</MDX>
      </Section>
      <ColouredPageSection
        title="Members"
        sectionDescription="People form the heart of SDEA. Here you can view the current committee and past committee members."
        firstColor={theme.colors.coral}
      >
        <FeaturedProgrammesContainer>
          {members
            .map(member => ({
              ...member,
              ...allUsers[member.email]?.childMdx?.frontmatter,
            }))
            .map(member => (
              <CommitteeMember
                key={member.email}
                title={member.role}
                name={member.name}
                excerpt={member.bio ?? ''}
                image={member.profile_image}
              />
            ))}
          {non_members.map(member => (
            <CommitteeMember
              key={member.name}
              title={member.role}
              name={member.name}
            />
          ))}
        </FeaturedProgrammesContainer>
      </ColouredPageSection>
    </WithHeroLayout>
  );
};

export const query = graphql`
  query($absolutePath: String!) {
    file(absolutePath: { eq: $absolutePath }) {
      childMdx {
        frontmatter {
          title
          content
          non_members {
            name
            role
          }
          members {
            email
            role
          }
          start_year
          end_year
        }
      }
    }

    users: allFile(filter: { sourceInstanceName: { eq: "user" } }) {
      edges {
        node {
          id
          childMdx {
            frontmatter {
              name
              email
              bio
              profile_image
            }
          }
        }
      }
    }
  }
`;

export default CommitteeTemplate;
